<template>
  <div class="uk-width-1-1">
    <div class="uk-background-default uk-padding-small">
      <form class="uk-form-stacked">
        <div class="uk-margin">
          <label class="uk-form-label">Titel</label>
          <input type="text" class="uk-input" v-model="form.title" />
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Inhalt</label>
          <editor :init="initEditor" v-model="form.content"></editor>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Link</label>
          <input type="text" class="uk-input" placeholder="http://" v-model="form.link" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme'
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import "tinymce/plugins/autoresize";
import Editor from '@tinymce/tinymce-vue';
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    Editor
  },
  data() {
    return {
      form: {},
      initEditor: {
        menubar: false,
        branding: false,
        relative_urls: false,
        statusbar: false,
        contextmenu: false,
        document_base_url: '/',
        min_height: 425,
        max_height: 800,
        toolbar: 'bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link',
        plugins: 'link autoresize lists advlist',
        autoresize_bottom_margin: 50,
        language_url: `/tinymce/langs/de.js`,
        language: 'de',
        skin_url: '/tinymce/skins/ui/oxide',
        content_css : '/tinymce/skins/content/default/content.min.css',
      }
    };
  },
  mounted() {
    this.form = this.data.props;
  },
  methods: {
    //
  }
};
</script>
