<template>
    <div>
      <form class="uk-form-stacked" v-if="!editItems">
        <div class="uk-width-1-1">
          <div class="uk-background-default uk-padding-small uk-box-shadow-medium">
            <div class="uk-margin">
              <div class="uk-h5 uk-text-uppercase">Einträge</div>
              <div
                v-for="(item, index) in localData.items"
                :key="index"
                class="uk-margin-small">
                <div class="uk-flex uk-flex-middle">
                  <button type="button" class="uk-button uk-flex uk-flex-middle uk-background-muted uk-text-left" @click.prevent="edit(item)">
                    {{ item.props.title }}
                    <span data-uk-icon="chevron-right"></span>
                  </button>
                  <a href="#" uk-icon="trash" class="uk-margin-left uk-text-muted" @click.prevent="removeItem(index)"></a>
                </div>
              </div>
              <div class="uk-margin">
                <button class="uk-button-secondary uk-button uk-border-rounded uk-button-small" @click.prevent="add">Eintrag hinzufügen</button>
              </div>
            </div>
            <hr>
            <div class="uk-h5 uk-text-uppercase">Accordion</div>
            <div class="uk-margin">
              <label class="uk-form-label">Verhalten</label>
              <label>
                <input type="checkbox" class="uk-checkbox" v-model="form.multiple"> Erlaube mehrere geöffnete Einträge
              </label><br>
               <label>
                <input type="checkbox" class="uk-checkbox" v-model="form.collapsible"> Alle Einträge dürfen geschlossen sein
              </label>
            </div>
            <div class="uk-grid uk-child-width-1-2@m" data-uk-grid>
              <div>
                <div class="uk-h5 uk-text-uppercase">Inhalt</div>
                <div class="uk-margin">
                  <label class="uk-form-label">Stil</label>
                  <select class="uk-select" v-model="form.content_style">
                    <option value="">Keiner</option>
                    <option value="lead">Lead</option>
                  </select>
                </div>
                <div class="uk-margin">
                  <label class="uk-form-label">Abstand oben</label>
                  <select class="uk-select" v-model="form.content_margin">
                    <option value="small">Klein</option>
                    <option value="">Standard</option>
                    <option value="medium">Medium</option>
                    <option value="large">Groß</option>
                    <option value="xlarge">Sehr Groß</option>
                    <option value="remove">Ohne</option>>
                  </select>
                </div>
              </div>
              <div>
                <div class="uk-h5 uk-text-uppercase">Link</div>
                <div class="uk-margin">
                  <label class="uk-form-label">Text</label>
                  <input type="text" v-model="form.link_text" class="uk-input">
                </div>
                <div class="uk-margin">
                  <label class="uk-form-label">Stil</label>
                  <select class="uk-select" v-model="form.link_style">
                    <option value="default">Button Standard</option>
                    <option value="primary">Button Primär</option>
                    <option value="secondary">Button Sekundär</option>
                    <option value="danger">Button Achtung</option>
                    <option value="text">Button Text</option>
                    <option value="">Link</option>
                    <option value="link-muted">Link Gedämpft</option>
                    <option value="link-text">Link Text</option>
                  </select>
                </div>
                <div class="uk-margin">
                  <label class="uk-form-label">Button Größe</label>
                  <select class="uk-select" v-model="form.link_size" :disabled="form.link_style === 'link-muted' || form.link_style === 'link-text'">
                    <option value="small">Klein</option>
                    <option value="">Standard</option>
                    <option value="large">Groß</option>
                  </select>
                </div>
                <div class="uk-margin">
                  <label class="uk-form-label">Abstand oben</label>
                  <select class="uk-select" v-model="form.link_margin">
                    <option value="small">Klein</option>
                    <option value="">Standard</option>
                    <option value="medium">Medium</option>
                    <option value="large">Groß</option>
                    <option value="xlarge">Sehr Groß</option>
                    <option value="remove">Ohne</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-margin">
            <button class="uk-button uk-button-default" @click.prevent="showSettings = !showSettings">
              Einstellungen
              <span uk-icon="arrow-right" v-if="!showSettings"></span>
              <span uk-icon="arrow-down" v-if="showSettings"></span>
            </button>
          </div>
        </div>
        <div class="uk-width-1-1 uk-margin" v-if="showSettings">
          <div class="uk-background-default uk-padding-small uk-box-shadow-medium">
              <general-settings :form="form" />
          </div>
        </div>
      </form>
      <div class="uk-width-1-1 uk-margin" v-else>
        <div class="uk-background-default uk-padding-small uk-box-shadow-medium">
          <accordion-item-edit :data="this.editItemData" />
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import AccordionItemEdit from './AccordionItemEdit'
import GeneralSettings from './../GeneralSettings'
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    AccordionItemEdit,
    GeneralSettings
  },
  data() {
    return {
      form: {},
      localData: {},
      editItems: false,
      editItemData: null,
      showSettings: false
    }
  },
  mounted() {
    this.form = this.data.props;
    this.localData = this.data;
  },
  methods: {
    async add() {
      let defaultProps = await axios.get(`./../../../assets/json/defaultProps/accordionItem.json`)
      const elemObj = {
        type: 'accordion_item',
        props: defaultProps.data
      };
      this.localData.items.push(elemObj)
      this.edit(elemObj)
    },
    edit(item) {
      this.editItems = true;
      this.editItemData = item;
    },
    removeItem(index) {
      this.localData.items.splice(index, 1)
    }
  }
}
</script>
